// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-orthodontist-tsx": () => import("./../../../src/pages/orthodontist.tsx" /* webpackChunkName: "component---src-pages-orthodontist-tsx" */),
  "component---src-pages-our-practice-tsx": () => import("./../../../src/pages/our-practice.tsx" /* webpackChunkName: "component---src-pages-our-practice-tsx" */),
  "component---src-pages-our-team-tsx": () => import("./../../../src/pages/our-team.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */),
  "component---src-pages-patient-information-emergencies-tsx": () => import("./../../../src/pages/patient-information/emergencies.tsx" /* webpackChunkName: "component---src-pages-patient-information-emergencies-tsx" */),
  "component---src-pages-patient-information-faq-tsx": () => import("./../../../src/pages/patient-information/faq.tsx" /* webpackChunkName: "component---src-pages-patient-information-faq-tsx" */),
  "component---src-pages-patient-information-first-visit-tsx": () => import("./../../../src/pages/patient-information/first-visit.tsx" /* webpackChunkName: "component---src-pages-patient-information-first-visit-tsx" */),
  "component---src-pages-patient-information-foods-and-habits-to-avoid-tsx": () => import("./../../../src/pages/patient-information/foods-and-habits-to-avoid.tsx" /* webpackChunkName: "component---src-pages-patient-information-foods-and-habits-to-avoid-tsx" */),
  "component---src-pages-patient-information-index-tsx": () => import("./../../../src/pages/patient-information/index.tsx" /* webpackChunkName: "component---src-pages-patient-information-index-tsx" */),
  "component---src-pages-patient-information-patient-form-tsx": () => import("./../../../src/pages/patient-information/patient-form.tsx" /* webpackChunkName: "component---src-pages-patient-information-patient-form-tsx" */),
  "component---src-pages-patient-information-payment-information-tsx": () => import("./../../../src/pages/patient-information/payment-information.tsx" /* webpackChunkName: "component---src-pages-patient-information-payment-information-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-referral-tsx": () => import("./../../../src/pages/referral.tsx" /* webpackChunkName: "component---src-pages-referral-tsx" */),
  "component---src-pages-service-policy-tsx": () => import("./../../../src/pages/service-policy.tsx" /* webpackChunkName: "component---src-pages-service-policy-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-what-we-do-braces-tsx": () => import("./../../../src/pages/what-we-do/braces.tsx" /* webpackChunkName: "component---src-pages-what-we-do-braces-tsx" */),
  "component---src-pages-what-we-do-clear-aligners-tsx": () => import("./../../../src/pages/what-we-do/clear-aligners.tsx" /* webpackChunkName: "component---src-pages-what-we-do-clear-aligners-tsx" */),
  "component---src-pages-what-we-do-clear-braces-tsx": () => import("./../../../src/pages/what-we-do/clear-braces.tsx" /* webpackChunkName: "component---src-pages-what-we-do-clear-braces-tsx" */),
  "component---src-pages-what-we-do-early-treatment-tsx": () => import("./../../../src/pages/what-we-do/early-treatment.tsx" /* webpackChunkName: "component---src-pages-what-we-do-early-treatment-tsx" */),
  "component---src-pages-what-we-do-index-tsx": () => import("./../../../src/pages/what-we-do/index.tsx" /* webpackChunkName: "component---src-pages-what-we-do-index-tsx" */),
  "component---src-pages-what-we-do-surgical-orthodontics-tsx": () => import("./../../../src/pages/what-we-do/surgical-orthodontics.tsx" /* webpackChunkName: "component---src-pages-what-we-do-surgical-orthodontics-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../../../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */)
}

